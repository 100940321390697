import React, {useState, useEffect, useContext} from 'react';
import Preloader from '../../components/Preloader/Preloader';
import SearchCardView from './SearchCardView';
import SearchTableView from './SearchTableView';
import { Offcanvas } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import OffcanvasTemplate from './OffcanvasTemplate';
import axios from 'axios';
import { books_category } from '../../ProData';
import AutoComplete from '../../components/AutoComplete';
import { useSnackbar } from 'notistack';
import BookCategoryCarosel from '../BookCategoryCarosel';
import {Helmet} from 'react-helmet-async';
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function BooksSearch() {
    const { validateLogin, loggedUser } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();

    const [searchResult, setSearchResult]=useState([]);
    const [searchValue, setSearchValue]= useState('');
    const [searchTopic, setSearchTopic]=useState('AccessionNo');
    const [show, setShow] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [selectedBook, setSelectedBook] = useState({accessionNo:"", bookAuthor:"",bookCategory:"NA",
      bookClassificationNo: "",
      bookEdition: "NA",
      bookPages: "",
      bookPrice: "0",
      bookPublishYear: "",
      bookPublisherName:"",
      bookScope:"",
      bookStatus:"",
      bookTitle:"",
      registeredOn:""});
    const [tableView,setTableView] = useState(true);
    const [cardView, setCardView] = useState(false);
    const [dispMsg, setDispMsg] = useState('');
    const [searchCompleted, setSearchCompleted] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [userType, setUserType] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (event, item) => {
      event.preventDefault();
      setSelectedBook(item);
      setShow(true);
    }
  
    useEffect(()=>{
      if(!loggedUser.isAuthenticated){
        validateLogin();
      }else{
        const temp=books_category.filter((item)=>item.categoryDisplay==="Yes");
        setCategoryList(temp);
      }
    },[loggedUser])
  
    const handleSearch=async(event)=>{
        event.preventDefault();
        try{
            //console.log("search value :", searchValue);
            //console.log("search topic :", searchTopic);
            if(searchValue.length===0){
              enqueueSnackbar('Search Value cannot be empty',{variant:'info'}); 
            }else{
              setLoaded(false);
              setDispMsg('');
              setSearchCompleted(false);
              setSearchResult([]);  
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID="VJCollege";
            var keysearchValue="";
            if(searchTopic==="BookCategory"){
              keysearchValue=searchValue;
            }else{
              keysearchValue=searchValue.toUpperCase();
            }
            await axios.get(`${config.api.invokeURL}/vjlibrary/search/books?fetchKey=${keysearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
             'x-api-key':config.api.key
            }
          }).then(async(response) => {
            //console.log("response : ", response.data);
            setDispMsg(response.data.length+' - Books Found');
            setSearchResult(response.data);
            enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
            setLoaded(true);
            setSearchCompleted(true);
        }).catch((error)=>{
          console.log("error:", error);
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })}
        }catch(error){
            //console.log("error1:", error);
            setLoaded(true);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    }

    const handleTableView=(event)=>{
      event.preventDefault();
      setTableView(true)
      setCardView(false);
    }

    const handleCardView=(event)=>{
      event.preventDefault();
      setTableView(false);
      setCardView(true);
    }

    const processSelection=(ivalue)=>{
      //console.log("ivalue:", ivalue);
      if(ivalue){
      if(searchTopic==="BookCategory"){
        setSearchValue(ivalue.name);
      }else{
        setSearchValue(ivalue.name);
      }
    }else{
      setSearchValue(userType);
    }
    }

  return (
    <div>
    <Helmet>
      <title>Book Search - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Book Search - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Book Search - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

        <Offcanvas show={show} onHide={handleClose} placement={['end']} scroll={true}	>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><i class="fa fa-id-card" aria-hidden="true"></i> {"  -   "}
{selectedBook.accessionNo}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <OffcanvasTemplate selectedBook={selectedBook} />
        </Offcanvas.Body>
    </Offcanvas>
    {!loaded &&  <Preloader show={loaded ? false : true} /> }
    <div>
    <section className="search_section layout_padding">
    <div className="container">
        <div className="heading_container ">
        <h2>
            Search your favourite Book's
        </h2>
        </div>
          <form action="#" className='card p-2'>
          <div className="row">
          <div className="col-md-3">
            <select name="searchOption" id="bookSearch"
            onChange={event=>setSearchTopic(event.target.value)}>
                  <option value="AccessionNo">Book ID</option>
                  <option value="BookTitle">Book Title</option>
                  <option value="BookAuthor">Author</option>
                  <option value="BookCategory">Category</option>
            </select>            
          </div>
          {searchTopic==="BookCategory"?
            <div className="search-bar-container col-md-7">
            <AutoComplete
                data={categoryList}
                onSelect={country => processSelection(country)}
            />
          </div>:
            <div className="col-md-7 ">
                <input type="text" placeholder="" onChange={event=>setSearchValue(event.target.value)}/>
            </div>}
               
            <div className="col-md-2 text-center">
              <button className='' onClick={event=>handleSearch(event)}>
                Search
              </button>
            </div>
            </div>
          </form>
    </div>
    {searchCompleted && <div className="row ml-4 p-4"  >
    <div className="col-md-6 ">
    <h4>{dispMsg}</h4>
    </div>
          {searchResult.length>0?<div className="col-md-6 btn-group" style={{textAlign:"end", alignItems:"flex-end"}}>
            <div style={{marginLeft:"75%", display:"flex"}}>
          <a href="#" className= {tableView?'btn btn-success':'btn btn-default'}><i onClick={event=>handleTableView(event)} className="fa fa-table fa-lg" aria-hidden="true" style={{fontSize:"20px"}}></i></a>
          <a href="#" className= {cardView?'btn btn-success':'btn btn-default'}><i onClick={event=>handleCardView(event)} className="fa fa-id-card fa-lg" aria-hidden="true" style={{fontSize:"20px"}}></i></a>
          </div>
          </div>:null}
        </div>}

        {tableView?<SearchTableView searchResult={searchResult} setSearchResult={setSearchResult} handleShow={handleShow}/>:null}
        {cardView? <SearchCardView searchResult={searchResult} handleShow={handleShow}/>:null}
        </section>
    </div>

    {loaded && <BookCategoryCarosel />}

    {/*<div className='container' style={{paddingTop:"50px"}}>
      <header className="section">
      {!loaded && <Preloader show={loaded ? false : true} />}
          <div className="search-book ">
              <form id="contact ">
                <div className='row' >
                   <div className="col-lg-12 col-md-12 col-sm-12 ">
                   <fieldset className='search-book-form d-inline-flex p-2'>
                   <Form.Select aria-label="Default select example" className=""
                  onChange={event=>setSearchTopic(event.target.value)}>
                  <option value="AccessionNo">Book ID</option>
                  <option value="BookTitle">Book Title</option>
                  <option value="BookAuthor">Author</option>
                  </Form.Select>
                      <input name="search-input" type="text" className="" id="search-input" placeholder="Enter the Search Input...." required 
                      onChange={event=>setSearchValue(event.target.value)} />
                     <button type="submit" id="form-submit" className="main-button" onClick={event=>handleSearch(event)}><i className="fa fa-search" aria-hidden="true"></i></button>
                 
                   </fieldset>
                  </div> 
                 
                </div>
              </form>
          </div>
        </header>
        <div className="row" style={{textAlign:"end", alignItems:"flex-end"}}>
          <div style={{padding:"5px"}} >
          <i onClick={event=>handleTableView(event)} className="fa fa-table p-2" aria-hidden="true" style={{fontSize:"25px"}}></i>
          
          <i onClick={event=>handleCardView(event)} className="fa fa-id-card p-2" aria-hidden="true" style={{fontSize:"25px"}}></i>
          </div>
        </div>
        <h4>{dispMsg}</h4>
        {tableView?<SearchTableView searchResult={searchResult} handleShow={handleShow}/>:null}
        {cardView? <SearchCardView searchResult={searchResult} handleShow={handleShow}/>:null}
    </div>*/}
    </div>
  )
}
