import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';
import {Helmet} from 'react-helmet-async';

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const capsRegx=/[A-Z ]+/;

export default function Login() {
    const { validateLogin, loginValidated, setLoginValidated, loggedUser } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    const [loaded, setLoaded] = useState(true);
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null,
        capsChar:null
      });
      const [passwordFocused, setPasswordFocused] = useState(false);
      const [newpasswordFocused, setNewPasswordFocused] = useState(false);
      const [newpasswordValidity, setNewPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null,
        capsChar:null
      });

  useEffect(() => {
    if(!loggedUser.isAuthenticated && !loginValidated){
      setLoginValidated(true);
      validateLogin();
    }
  }, [])
  
const handleLogin = async(event) => {
    event.preventDefault();
    try{
        setLoaded(false);
        setNewPasswordFocused(false);
        if(username===""||password===""){
          enqueueSnackbar("Username and Password cannot be empty",{variant:'info'});
          setLoaded(true);
        }else{
     await signIn({ 
      username, 
      password,
      })
     .then(async(user)=>{
      //console.log("User" , user);
      if(!user.isSignedIn && user.nextStep.signInStep==="CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"){
        //setUser(user);
        setPasswordFocused(false);
        setShowNewPass(true);
        enqueueSnackbar("Set up your New Password",{variant:'info'});
      }else{  
        await validateLogin();
     }
     setLoaded(true);
    })
     .catch((error)=>{
      //console.log("Error :", error);
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
     })
  }
      }catch(error){
        //console.log("Error", error);
        setLoaded(true);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});            
      }
  };
  
  const handleChangePassword =async(event)=>{
    event.preventDefault();
    try{
      setLoaded(false);
      const attributes = {
        email: username,
       };
       await confirmSignIn({
        challengeResponse: newPassword,
       })
      .then(async(user) => {
        //console.log(user);
        if(user.isSignedIn){
          await validateLogin();
          enqueueSnackbar("Authenticated Successfully!",{variant:'success'});
          setLoaded(true);    
        }
        //setSidebarStatus("Orginal");
    }).catch(e => {
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + e.message,{variant : 'warning'});
    });
    }catch(error){
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }
  
  const onChangePassword = password => {
    setPassword(password);
    setPasswordValidity({
        minChar: password.length >= 8 ? true : false,
        number: isNumberRegx.test(password) ? true : false,
        specialChar: specialCharacterRegx.test(password) ? true : false,
        capsChar:capsRegx.test(password)?true:false
    });
  };
  
  const onNewChangePassword = password => {
    setNewPassword(password);
    setNewPasswordValidity({
      minChar: password.length >= 8 ? true : false,
      number: isNumberRegx.test(password) ? true : false,
      specialChar: specialCharacterRegx.test(password) ? true : false,
      capsChar:capsRegx.test(password)?true:false
    });
  };
  
  return (
    <div className='p-3'>
    <Helmet>
      <title>Sign In - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Sign In - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Sign In - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

        {!loaded &&  <Preloader show={loaded ? false : true} /> }
        <div className='container card justify-content-center' style={{borderRadius:"30px"}}>
         <div className="text-center p-3">
          <div className='text-center'>
          <h1>Sign In</h1>
          </div>
                    <div className="contact-form text-center m-3" >
                          <div className="row d-flex justify-content-center p-2">
                            <div className="col-lg-2 col-md-12 col-sm-12">
                                <label>Username</label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                <input name="email" type="email" className="form-control" id="email" placeholder="Email Address" required 
                                onChange={event=>setUsername(event.target.value)} value={username}/> 
                            </div>
                            </div>
                            {!showNewPass?<div className="row d-flex justify-content-center p-2">
                                <div className="col-lg-2 col-md-12 col-sm-12">
                                <label>Password</label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                <input name="password" type="password" className="form-control" id="password" placeholder="Password" required 
                    onFocus={() => setPasswordFocused(true)}
                    onChange={e =>
                        onChangePassword(e.target.value)}            
                                value={password}/>
                              </div>
                              </div>:<div className="row d-flex justify-content-center p-2">
                              <div className="col-lg-2 col-md-12 col-sm-12">
                              <label>New Password</label>
                              </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                <input name="password" type="password" className="form-control" id="password" placeholder="New Password" required 
                                  onFocus={() => setNewPasswordFocused(true)}
                                  onChange={e =>
                                      onNewChangePassword(e.target.value)}            
                                value={newPassword}/>
                            </div>
                            </div>}
                            {passwordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={passwordValidity}
                                    />
                                )}
                            {newpasswordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={newpasswordValidity}
                                    />
                                )}

                            <div className="row d-flex justify-content-center ">
                            <div>
                              <Link to="/forgotpassword">
                              Forgot password ?
                              </Link>
                            </div>
                            {!showNewPass?<div className="col-lg-12  text-center">
                              <a>
                                <button type="submit" className="btn-box mt-3" onClick={event=>handleLogin(event)}>Sign In</button>
                              </a>
                            </div>:<div className="col-lg-12  text-center">
                              <fieldset>
                                <button type="submit" className="btn-box mt-3" onClick={event=>handleChangePassword(event)}>Change Password</button>
                              </fieldset>
                            </div>}
                          </div>
                    </div>
                </div>
            </div>
</div>
  )
}
