import React from 'react';
import { Link } from 'react-router-dom';
import banner from '../assets/images/slider/banner.jpeg';
export default function AboutUs() {
  return (
    <div>
    <section class="about_section layout_padding">
    <div class="container ">
      <div class="row">
        <div class="col-md-6">
          <div class="img-box">
            <img src={banner} alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
                About Our Library
              </h2>
            </div>
            <p>
            In the heart of the digital age, where information flows freely, a Christian digital library stands as a beacon of faith and knowledge, offering a vast array of resources to nourish the soul and enlighten the mind. These libraries provide a comprehensive collection of digital resources, including ebooks, audiobooks, articles, journals, videos, and multimedia content, all curated with a Christian perspective.
            </p>
            <Link to="/aboutus" style={{cursor:"pointer"}}>Read More</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}
