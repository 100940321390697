import React, {useState, useContext, useEffect} from 'react';
import { Col, Row, Form, Card, Modal, Table } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import Preloader from '../../components/Preloader/Preloader';
//import BarQrScanner from '../barcodeScan/BarQrScanner';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function IssueBooks() {
    const { validateLogin, loggedUser, handleImageError, generateDate, generateReturnDate,generateAccessionNo } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [searchBook, setSearchBook] = useState({type:'AccessionNo', value:''});
    const [searchUser, setSearchUser] = useState({type:'RegistrationID', value:''});
    const [bookResult, setBookResult] = useState([]);
    const [userResult, setUserResult] = useState([]);
    const [bookSelect, setBookSelect]= useState();
    const [userSelect, setUserSelect]= useState();
    const [loaded, setLoaded] = useState(true);
    const [activateScanner, setActivateScanner]= useState(false);
    const [issuedBooks, setIssuedBooks]=useState([]);
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('')
    const refBook = React.useRef([]);
    const refUser = React.useRef([]);


    useEffect(()=>{
      if(!loggedUser.isAuthenticated){
        validateLogin();
      }else{
          handleReset();
      }
    },[loggedUser])

    const handleBookSearch=async(event)=>{
        event.preventDefault();
        //console.log("search book : ", searchBook);
        try{
          UncheckedBooks();
            setLoaded(false);
            //setDispMsg('');
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID="VJCollege";
            const searchValue = searchBook.value;
            const searchTopic = searchBook.type;
            await axios.get(`${config.api.invokeURL}/vjlibrary/search/books?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
           //setDispMsg(response.data.length+' - Books Found');
            setBookResult(response.data);
            UncheckedBooks();
            setBookSelect();
            enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
            //console.log("error:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    

    }

    const handleSortKey =async(event, value)=>{
      event.preventDefault();
      console.log("clicked");
      /*if(value==="AccessionNo"){
        const tempOrginalValue=bookResult;
        setBookResult([]);
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.accessionNo - b.accessionNo;});
        console.log("Sorted AccessionNo Array ", sortedOptionArray);
        await setBookResult(sortedOptionArray);
      }else if(value==="BookTitle"){
        const tempOrginalValue=bookResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.bookTitle - b.bookTitle;});
        console.log("Sorted Book Array ", sortedOptionArray);
        await setBookResult(sortedOptionArray);
      }else if(value==="BookingDate"){
        var tempOrginalValue=bookResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.bookedOn - b.bookedOn;});
        tempOrginalValue.sort((a, b) => (a.name - b.name ? 1 : -1));
    
        console.log("Sorted Book Array ", tempOrginalValue);
        await setBookResult(sortedOptionArray);
      }*/
      
    }

    const handleUserSearch=async(event)=>{
        event.preventDefault();
        //console.log("user details : ", searchUser);
        try{
            //UncheckedUsers();
            if(searchUser.value===""){
              enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant:'warning'}); 
            }else{
              setLoaded(false);
              const searchValue=searchUser.value;
              const searchTopic=searchUser.type;
            await axios.get(`${config.userapi.invokeURL}/dashboard/student/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
              headers: {
                //Authorization: idToken,
                //'x-api-key':config.userapi.key
              }
            }).then(async(response) => {
              setUserResult(response.data);
              UncheckedUsers();
              setUserSelect();
              enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
              setLoaded(true);
          }).catch((error)=>{
            //console.log("Error :", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
        })
        getBooks();
       /* const clientID="VJCollege";
        //console.log("search value :", searchValue);
        const tempTopic="UserID";
       // const { idToken1 } = (await fetchAuthSession()).tokens ?? {};
        await axios.get(`${config.api.invokeURL}/vjlibrary/issue?fetchKey=${searchValue}&fetchOption=${tempTopic}&clientID=${clientID}`, {
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         console.log("response : ", response.data);
         //setDispMsg(response.data.length+' - Books Found');
          setIssuedBooks(response.data);
          enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
          setLoaded(true);
      }).catch((error)=>{
        console.log("Error1 :", error);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
    })*/
  }
  }catch(error){
    //console.log("Error2 :", error);
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  }
}

const getBooks=async()=>{
  try{
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const clientID="VJCollege";
    const tempTopic="UserID";
    const searchValue=searchUser.value;
    await axios.get(`${config.api.invokeURL}/vjlibrary/issue?fetchKey=${searchValue}&fetchOption=${tempTopic}&clientID=${clientID}`, {
      headers: {
        Authorization: idToken,
        'x-api-key':config.api.key
      }
    }).then(async(response) => {
    // console.log("response : ", response.data);
     //setDispMsg(response.data.length+' - Books Found');
      setIssuedBooks(response.data);
      //enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
      setLoaded(true);
  }).catch((error)=>{
   // console.log("Error1 :", error);
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
})
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  }
}

    const handleBookSelection=(event, item)=>{
        //console.log("item:",item);
        setBookSelect(item);
    }

    const handleUserSelection=(event, item)=>{
        //console.log("event:",item);
        setUserSelect(item);
    }

    const handleBookIssue=async(event)=>{
        event.preventDefault();
        try{
            if(!bookSelect){
              enqueueSnackbar("Select the Book!",{variant : 'info'});
            }else if(!userSelect){
              enqueueSnackbar("Select the User!",{variant : 'info'});
            }else if(issuedBooks.length>=20){
              enqueueSnackbar("20 books limit reached!",{variant : 'info'});
            }else if(bookSelect.bookStatus==="Active"){
                setLoaded(false);
                const todayDate=await generateDate();
                const returnDate=await generateReturnDate(30);
                const tbookingid=await generateAccessionNo();
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                const params={
                  clientID:loggedUser.website,
                  issueID:"BK-"+tbookingid,
                  accessionNo:bookSelect.accessionNo,
                  bookAuthor:bookSelect.bookAuthor,
                  bookClassificationNo:bookSelect.bookClassificationNo,
                  bookCategory:bookSelect.bookCategory,
                  bookEdition:bookSelect.bookEdition,
                  bookPages:bookSelect.bookPages,
                  bookPrice:bookSelect.bookPrice,
                  bookPublishYear:bookSelect.bookPublishYear,
                  bookPublisherName:bookSelect.bookPublisherName,
                  bookScope:bookSelect.bookScope,
                  bookStatus:"Issued",
                  bookTitle:bookSelect.bookTitle,
                  bookedOn:todayDate,
                  bookedfor:userSelect.applicantName,
                  userID:userSelect.registrationNo,
                  userEmailID:userSelect.emailID,
                  bookedFrom:todayDate,
                  bookedTo:returnDate,
                  updatedBy:loggedUser.nickname,
                  updatedOn:todayDate
                }; 
               // console.log("Params :", params);
                //setLoaded(true);
                await axios.post(`${config.api.invokeURL}/vjlibrary/issue`,params, {  
                  headers: {
                    Authorization: idToken,
                    'x-api-key':config.api.key
                  }
                }).then(async(response) => {
                  const { idToken } = (await fetchAuthSession()).tokens ?? {};
                  const tempeid=['rjosephxavier@yahoo.com','rjosephxavier@gmail.com'];
                  const eamilParams={
                    accessionNo:bookSelect.accessionNo,
                    bookedfor:userSelect.applicantName,
                    bookTitle:bookSelect.bookTitle,
                    bookedFrom:todayDate,
                    bookedTo:returnDate,
                    bookAuthor:bookSelect.bookAuthor,
                    //userEmailID:userSelect.emailID
                    userEmailID:tempeid
                  };
                  const res=axios.post(`${config.api.invokeURL}/vjlibrary/notification`,eamilParams, {  
                    headers: {
                      Authorization: idToken,
                      'x-api-key':config.api.key
                    }
                  });
                  enqueueSnackbar("Book '" + bookSelect.accessionNo + "' successfully assigned to '" + userSelect.applicantName +"'",{variant:'success'});
                  getBooks();
                  handleReset();
                  setLoaded(true)
              }).catch((error)=>{
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
              setLoaded(true);
              window.scrollTo(0,0);
            })
            }else{
              enqueueSnackbar("This book is not available - " + bookSelect.accessionNo,{variant:'info'});
            }
        }catch(error){
            //console.log("Error : ", error);
            setLoaded(true);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
    }

    const handleReset=()=>{
      setSearchBook({type:'AccessionNo', value:''});
      //setSearchUser({type:'RegistrationID', value:''});
      setBookResult([]);
      //setUserResult([]);
      setBookSelect();
      //setUserSelect();  
    }

    const handleClose = () => setShow(false);

    const handleShow = () => {
      //event.preventDefault();
      setShow(true);
      setModalTitle("Book's Issued");
    }

    const UncheckedBooks = () => {
      //console.log(ref.current.length)
      //console.log("ref :", ref);
      for (let i = 0; i < refBook.current.length; i++) {
        if(refBook.current[i]!==null){
          refBook.current[i].checked = false;
        }
      }
    }

    const UncheckedUsers = () => {
     // console.log(ref.current.length)
     // console.log("ref :", refUser);
      for (let i = 0; i < refUser.current.length; i++) {
        if(refUser.current[i]!==null){
          refUser.current[i].checked = false;
        }
      }
    }
    
    


  return (
    <div>
          <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

      <Modal show={show} onHide={handleClose} size='xl' >
        <Modal.Header closeButton>
        <Modal.Title>{modalTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
          {issuedBooks.length>0 ? <Table striped bordered hover responsive>
        <thead >
        <tr className='text-center'>
          <th>#</th>
          <th>Accession No  <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Book Title <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Author  <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Call No <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Issued To <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
          <th>Booking Date  <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookingDate")}></i></th>
          <th>Return Date <i class="fa fa-sort" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle")}></i></th>
        </tr>
        </thead>
        <tbody>
        {issuedBooks.map((item,index)=>{return(
          <tr key={index}>
            <td>{index}</td>
            <td>{item.accessionNo}</td>
            <td>{item.bookTitle}</td>
            <td>{item.bookAuthor}</td>
            <td>{item.bookClassificationNo}</td>
            <td>{item.bookedfor}</td>
            <td>{item.bookedOn}</td>
            <td>{item.bookedTo}</td>
          </tr>
        )})}
        </tbody>
      </Table>:null}
        </div>
        </Modal.Body>
    </Modal>
    <div className='container' style={{padding:"50px"}}>
    <div className="section">
    {!loaded && <Preloader show={loaded ? false : true} />}
    <h3 style={{textAlign:"center"}}>Issue Book's</h3>
     {/*User Details*/}
     <Card style={{padding:"0px"}}>
      <Card.Body>
        <Form>
        <Row>
            <Col lg={3} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Search Type</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={searchUser.type}
                onChange={event=>{setSearchUser({...searchUser, type:event.target.value})}} 
                    >
                  <option value="UserID">User ID</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Search Details</Form.Label>
                <Form.Control required type="text" placeholder="Enter author name...."
                    value={searchUser.value} 
                    onChange={event=>{setSearchUser({...searchUser, value:(event.target.value).toUpperCase()})}}
          />
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-1">
            <Form.Group id="middleName">
                <button className='btn-box' style={{marginTop:"30px"}} onClick={event=>handleUserSearch(event)}>Search User</button>
                </Form.Group>
            </Col>
          </Row>
          </Form>
          {userResult.length>0 ? <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Registration No</th>
          <th>User Name</th>
          <th>Books Issued </th>
          <th>Actions</th>
        </tr>
        </thead>
      <tbody>
      {userResult.map((item,index)=>{return(
        <tr>
          <td><Form.Check inline
            type='radio'
            name="userGroup"
            id="userGroup"
            onChange={event=>handleUserSelection(event, item)}
            onClick={event=>handleUserSelection(event, item)}
            ref={(element) => { refUser.current[index] = element }} 

          /></td>
          <td>{item.registrationNo}</td>
          <td><img id="profile" src={`${config.s3.profileUrl}${item.applicationNo}${"/"}${item.avatar}`} 
          onError={handleImageError}
          alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%"}} />{item.applicantName}</td>
          <td>{issuedBooks.length}</td>
          <td><i className="fa fa-eye" aria-hidden="true" onClick={event=>handleShow()}></i></td>
        </tr>
      )})}
      </tbody>
    </Table>:null}
      
          </Card.Body>
        </Card>
    
    {/*Book Details*/}
    <Card style={{padding:"0px"}}>
      <Card.Body>
        <Form>
        <Row>
            <Col lg={3} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Search Type</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={searchBook.type}
                onChange={event=>{setSearchBook({...searchBook, type:event.target.value})}} 
                    >
                  <option value="AccessionNo">Book ID</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Search Details</Form.Label>
                <Form.Control required type="text" placeholder="Enter search details...."
                    value={searchBook.value} 
                    onChange={event=>{setSearchBook({...searchBook, value:event.target.value})}}
          />
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-1">
                <button className='btn-box' style={{marginTop:"30px"}} onClick={event=>handleBookSearch(event)}>Search Book</button>
            </Col>
          </Row>
        </Form>
        {bookResult.length>0 ? <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Accession No</th>
          <th>Book Title</th>
          <th>Author</th>
          <th>Call No</th>
          <th>Status</th>
        </tr>
        </thead>
      <tbody>
      {bookResult.map((item,index)=>{return(
        <tr>
          <td><Form.Check inline
            type='radio'
            name="bookGroup"
            id="bookGroup"
            onChange={event=>handleBookSelection(event, item)}
            onClick={event=>handleBookSelection(event, item)}
            ref={(element) => { refBook.current[index] = element }} 
          /></td>
          <td>{item.accessionNo}</td>
          <td>{item.bookTitle}</td>
          <td>{item.bookAuthor}</td>
          <td>{item.bookClassificationNo}</td>
          <td>{item.bookStatus}</td>
        </tr>
      )})}
      </tbody>
    </Table>:null}
      </Card.Body>
    </Card>
        <div className="mt-3 text-center">
        <button className='btn-box' onClick={event=>handleBookIssue(event)}>Issue Books</button>
        </div>
    </div>
    </div>
    </div>
  )
}
