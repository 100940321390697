import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

//import banner from '../assets/images/slider/banner.jpeg';
import slider1 from '../assets/images/slider/slider1.jpeg';
import slider2 from '../assets/images/slider/slider2.jpeg';
import slider3 from '../assets/images/slider/slider3.jpeg';

export default function MainSliderCarosel() {
  return (
    <div>
    <section className="slider_section ">
        <Carousel pause={'hover'} fade indicators={false} controls={false}>
        <Carousel.Item >
        <div className="container ">
        <div className="row">
        <div className="col-md-6">
            <div className="detail-box">
            {/*<h5>
                Bostorek Bookstore
            </h5>*/}
            <h1>
            Comprehensive and Accessible Content
            </h1>
            <p>
            Diverse collection: A rich array of digital resources, including ebooks, audiobooks, articles, journals, videos, and multimedia content.
            Easy discoverability: Intuitive search functions and metadata tagging to help users find what they need quickly.
            </p>
            <Link to="/aboutus" style={{cursor:"pointer"}}>
                Read More
            </Link>
            </div>
        </div>
        <div className="col-md-6">
            <div className="img-box">
            <img src={slider1} alt="" />
            </div>
        </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item >
        <div className="container ">
              <div className="row">
                <div className="col-md-6">
                  <div className="detail-box">
                    {/*<h5>
                      Bostorek Bookstore
                    </h5>*/}
                    <h1>
                    Robust Technology Infrastructure
                    </h1>
                    <p>
                    Reliability and scalability: A stable platform capable of handling increased user traffic and growing content collections.
                    Security: Protecting user data and intellectual property with robust security measures.
                    </p>
                    <Link to="/aboutus" style={{cursor:"pointer"}}>
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src={slider2} alt="" />
                  </div>
                </div>
              </div>
            </div>
        </Carousel.Item>
        <Carousel.Item >
        <div className="container ">
              <div className="row">
                <div className="col-md-6">
                  <div className="detail-box">
                    {/*<h5>
                      Bostorek Bookstore
                    </h5>*/}
                    <h1>
                    Continuous Innovation and User Focus
                    </h1>
                    <p>
                    User-friendly interface: A clean, intuitive design that enhances user experience.
                    Adaptability: Staying up-to-date with technological advancements and user needs.
                    </p>
                    <Link to="/aboutus" style={{cursor:"pointer"}}>
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src={slider3} alt="" />
                  </div>
                </div>
              </div>
            </div>
        </Carousel.Item>
        </Carousel>
    </section>
    </div>
    )
}
