import React, { useEffect, useContext } from 'react'
import MainSlider from './MainSlider';
//import BookCategories from './BookCategories';
import AboutUs from './AboutUs';
//import CustomerSection from './CustomerSection';
//import BlogSection from './BlogSection';
import { ProductContext } from '../ProductProvider';
import ContactUs from './ContactUs';
import BookCategoryCaroselHome from './BookCategoryCaroselHome';
import {Helmet} from 'react-helmet-async';
import MainSliderCarosel from './MainSliderCarosel';

export default function HomePage() {
  const { loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    //console.log("logged user ", loggedUser);
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
  },[loggedUser])

  return (
    <div>
    <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

        <MainSliderCarosel />
        <BookCategoryCaroselHome />
        {/*<BookCategories />*/}
        <AboutUs />
        {/*<CustomerSection />
        <BlogSection />*/}
        <ContactUs />
    </div>
  )
}
